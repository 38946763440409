import type { Dispatch, SetStateAction } from "react";
import { useState, useEffect, useRef } from "react";
import { Image } from "~/components/ui/image";
import type { ImageProps } from "~/components/ui/image";
import { useOnScreen } from "~/hooks/common";
import type { ReactPlayerProps } from "react-player/lazy";
import ReactPlayer from "react-player/lazy";
import { spTrackVideo } from "~/utils/tracking";

type HandleOnStateChangeProps = {
	videoState: -1 | 0 | 1 | 2 | 3 | 4 | 5;
	duration: number;
	currentTime: number;
	videoTitle: string;
	id: string;
	hasStarted: boolean;
	setHasStarted: Dispatch<SetStateAction<boolean>>;
};

export type Props = {
	id: string;
	children?: string;
	loading?: ImageProps["loading"];
	image?: ImageProps;
} & ReactPlayerProps;

export const handleOnStateChange = ({
	videoState,
	duration,
	currentTime,
	videoTitle,
	id,
	hasStarted,
	setHasStarted,
}: HandleOnStateChangeProps) => {
	let state: string;

	switch (videoState) {
		case -1:
			state = "unstarted";
			break;
		case 0:
			state = "ended";
			setHasStarted(false);
			break;
		case 1:
			state = "playing";
			break;
		case 2:
			state = "paused";
			break;
		case 3:
			state = "buffering";
			break;
		case 4:
			state = "cued";
			break;
		default:
			state = "unknown";
	}

	const progress =
		duration > 0 ? Math.floor((currentTime / duration) * 100) : 0;

	const percentagesArr = [25, 50, 75, 100];

	let progressPercentage = 0;
	let i = 0;
	while (percentagesArr[i] <= progress) {
		progressPercentage = percentagesArr[i++];
	}

	const isVideoStarted = !hasStarted && currentTime === 0;

	spTrackVideo({
		state: isVideoStarted ? "start" : state,
		title: videoTitle,
		url: `https://www.youtube.com/watch?v=${id}`,
		duration: duration,
		currentTime: currentTime,
		percentage: progressPercentage,
	});

	if (isVideoStarted) {
		setHasStarted(true);
	}
};

export function Youtube({
	id,
	children,
	loading = "lazy",
	image,
	...rest
}: Props) {
	const [showYt, setShowYt] = useState(false);
	const [currentTime, setCurrentTime] = useState(0);
	const [duration, setDuration] = useState(0);
	const [isThumbNailResAvailable, setIsThumbNailResAvailable] = useState(true);
	const [hasStarted, setHasStarted] = useState(false);

	const imgRef = useRef<HTMLImageElement>(null);

	const ytContainerRef = useRef<HTMLDivElement>(null);
	const ytPlayerRef = useRef<ReactPlayer>(null);
	const isVisible = useOnScreen(ytContainerRef, "-100px");

	useEffect(() => {
		const yt = ytPlayerRef?.current;
		if (yt && !isVisible) {
			yt.getInternalPlayer().pauseVideo();
		}
	}, [isVisible]);

	const videoTitle = children ? children : "Youtube embedded";

	function handleOnLoad() {
		const naturalWidth = imgRef?.current?.naturalWidth || 0;
		const isThumbNailResAvailable = naturalWidth > 120;

		if (isThumbNailResAvailable) {
			return;
		}

		setIsThumbNailResAvailable(isThumbNailResAvailable);
	}

	const handleProgress = ({ playedSeconds }: { playedSeconds: number }) => {
		setCurrentTime(playedSeconds);
		if (duration - 1 <= Math.ceil(playedSeconds)) {
			handleOnStateChange({
				duration,
				currentTime,
				videoTitle,
				videoState: 0,
				id,
				hasStarted,
				setHasStarted,
			});
		}
	};

	const opts = {
		youtube: {
			embedOptions: {
				playerVars: { use_native_controls: 1 },
			},
		},
	};

	return (
		<div ref={ytContainerRef}>
			{showYt ? (
				<ReactPlayer
					{...rest}
					width="100%"
					ref={ytPlayerRef}
					height="100%"
					playing={true}
					className="relative z-10 aspect-video rounded-lg bg-black"
					url={`https://www.youtube.com/watch?v=${id}`}
					title={videoTitle}
					config={opts}
					loop={true}
					onDuration={setDuration}
					onPlay={() =>
						handleOnStateChange({
							duration,
							currentTime,
							videoTitle,
							videoState: 1,
							id,
							hasStarted,
							setHasStarted,
						})
					}
					onPause={() =>
						handleOnStateChange({
							duration,
							currentTime,
							videoTitle,
							videoState: 2,
							id,
							hasStarted,
							setHasStarted,
						})
					}
					onBuffer={() =>
						handleOnStateChange({
							duration,
							currentTime,
							videoTitle,
							videoState: 3,
							id,
							hasStarted,
							setHasStarted,
						})
					}
					onSeek={() =>
						handleOnStateChange({
							duration,
							currentTime,
							videoTitle,
							videoState: 4,
							id,
							hasStarted,
							setHasStarted,
						})
					}
					onProgress={handleProgress}
				/>
			) : (
				<div
					className="group/player relative flex aspect-video cursor-pointer items-center justify-center overflow-hidden rounded-lg bg-black"
					onClick={() => setShowYt(true)}
				>
					{/* Align with Wistia player */}
					<button
						className="absolute flex h-[80px] w-[125px] items-center justify-center bg-secondary-60 transition-colors duration-150 group-hover/player:bg-secondary-40"
						aria-label={`Play video ${children}`}
					>
						<div className="h-0 w-0 border-b-[18px] border-l-[26px] border-t-[18px] border-b-transparent border-l-white border-t-transparent"></div>
					</button>
					{image?.src ? (
						<Image {...image} />
					) : (
						<Image
							src={
								isThumbNailResAvailable
									? `https://img.youtube.com/vi/${id}/maxresdefault.jpg`
									: `https://img.youtube.com/vi/${id}/maxres1.jpg`
							}
							ref={imgRef}
							onLoad={handleOnLoad}
							alt={children ? children : "Youtube thumbnail"}
							loading={loading}
						/>
					)}
				</div>
			)}
		</div>
	);
}
