import clsx from "clsx";
import ReactMarkdown from "react-markdown";
import type { HeadingProps } from "react-markdown/lib/ast-to-react";
import React from "react";
import rehypeSlug from "rehype-slug";
import remarkGfm from "remark-gfm";
import remarkDirective from "remark-directive";
import remarkDirectiveRehype from "remark-directive-rehype";
import remarkRemoveComments from "remark-remove-comments";
import remarkBreaks from "remark-breaks";
import remarkMath, { type Options as MathOptions } from "remark-math";
import rehypeKatex from "rehype-katex";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import type { AdsBannerProps } from "~/components/ads-banner";
import { AdsBanner } from "~/components/ads-banner";
import { MermaidClient as Mermaid } from "~/components/mermaid";
import { NumberItem, type NumberProps } from "~/components/number-list";
import type { AlertProps } from "~/components/alert";
import { Alert } from "~/components/alert";
import { Image } from "~/components/ui/image";
import { Table } from "~/components/ui/table";
import {
	Blockquote,
	H2,
	H3,
	H4,
	H5,
	H6,
	Paragraph,
} from "~/components/ui/typography";
import { BulletList, OrderList, ListItem } from "~/components/list";
import {
	getImageBuilder,
	getImageProps,
	isSanityImgUrl,
	parseImgSrc,
} from "~/utils/images";
import { Link } from "./ui/link";
import { asText } from "~/utils/sanity-helpers";
import { Icon } from "./ui/icons";
import { Divider } from "./ui/divider";
import useScrollSpy from "~/hooks/useScrollSpy";
import { Youtube } from "./youtube";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Quote from "~/images/shape-quote.svg";
import { spTrackWebInteraction } from "~/utils/tracking";

declare global {
	// eslint-disable-next-line @typescript-eslint/no-namespace
	namespace JSX {
		// this merges with the existing intrinsic elements, adding 'my-custom-tag' and its props
		interface IntrinsicElements {
			banner: AdsBannerProps;
			youtube: { id: string; children: string };
			mermaid: { children: React.ReactNode & React.ReactNode[] };
			alert: AlertProps;
			statistic: NumberProps;
		}
	}
}

interface Props {
	// we need to remove Remix related
	//when rendering this component out side the Remix context
	shouldUseRemixRelatedContext?: boolean;
	content: string;
	withToc?: boolean;
	tocTitle?: string;
	tocTop?: string;
	fullWidth?: boolean;
}

function HeadingAnchorLink({ id }: { id: string }) {
	return (
		<a
			className="invisible absolute right-full top-0 flex h-full items-center px-2 group-hover:visible"
			aria-hidden="true"
			tabIndex={-1}
			href={`#${id}`}
			rel="nofollow"
			onClick={() =>
				spTrackWebInteraction({
					object: "heading",
					action: "anchor",
					value: `#${id}`,
				})
			}
		>
			<Icon width="16px" name="link" color="secondary" />
		</a>
	);
}

function CopyCodeButton(props: CopyToClipboard.Props) {
	const [copyOk, setCopyOk] = React.useState(false);

	const handleClick = () => {
		setCopyOk(true);

		spTrackWebInteraction({
			object: "code",
			action: "copy",
			value: props.text,
		});

		setTimeout(() => {
			setCopyOk(false);
		}, 1000);
	};

	return (
		<CopyToClipboard onCopy={handleClick} text={props.text}>
			<button title="Copy to clipboard" className="group" type="button">
				{copyOk ? (
					<div className="flex items-center gap-3">
						<Paragraph as="span" size="body-small">
							Copied!
						</Paragraph>
						<Icon name="check" color="success" width="18" height="18" />
					</div>
				) : (
					<span className="text-grey-60 group-hover:text-grey-80">
						<span className="sr-only">Copy to clipboard</span>
						<Icon color="current" name="copy" width="18" height="18" />
					</span>
				)}
			</button>
		</CopyToClipboard>
	);
}

export function generateId(text: string) {
	return text.toLowerCase().replace(/[^a-z0-9]+/g, "-");
}

const remarkMathOptions: MathOptions = {
	/**
	 * Single dollar symbol to render inline epxressions in Math often interferes with “normal” dollars in text.
	 * We turn this off so we use two-dollar symbols ($$) instead
	 */
	singleDollarTextMath: false,
};

/**
 * remark-math only supports 2 dollar symbols for the math inline syntax. eg: $$ <math here> $$
 * However, github supports different syntax, starting the expression with $` and end it with `$. eg: $` <math here> `$
 *
 * Our editors prefer the Github syntax. We will support that.
 *
 * This function is to transform the Github syntax to the remark-math syntax.
 */
function fixInlineMathSyntax(content: string) {
	return content
		.replaceAll("$`", "$$$") // replace $`with $$
		.replaceAll("`$", "$$$"); // replace `$ with $$
}

export function MarkDown({
	content,
	shouldUseRemixRelatedContext = true,
	withToc = false,
	tocTitle,
	tocTop,
	fullWidth = false,
}: Props) {
	// https://gist.github.com/sobelk/16fe68ff5520b2d5e2b6d406e329e0de
	const toc: {
		level: number;
		id: string;
		title: string;
	}[] = [];

	const addToTOC = ({
		children,
		...props
	}: React.PropsWithChildren<HeadingProps>) => {
		const level = Number(props.node.tagName.match(/h(\d)/)?.slice(1));

		const headingSize = (id?: string) => {
			switch (props.node.tagName) {
				case "h1":
					return (
						<H2 id={id} className="group relative mb-6 mt-9 first:mt-0">
							{id && <HeadingAnchorLink id={id} />}
							{children}
						</H2>
					);
				case "h2":
					return (
						<H2 id={id} className="group relative mb-6 mt-9 first:mt-0">
							{id && <HeadingAnchorLink id={id} />}
							{children}
						</H2>
					);
				case "h3":
					return (
						<H3 id={id} className="group relative mb-6 mt-7 first:mt-0">
							{id && <HeadingAnchorLink id={id} />}
							{children}
						</H3>
					);
				default:
					return (
						<H2 id={id} className="group relative mb-6 mt-9 first:mt-0">
							{id && <HeadingAnchorLink id={id} />}
							{children}
						</H2>
					);
			}
		};

		// return heading as string only as heading can consist of object with <code> or styled italic/bold
		let headingString = "";

		for (const child of children) {
			if (typeof child === "string") {
				headingString += child;
			} else if (
				typeof child === "object" &&
				child !== null &&
				"props" in child &&
				child.props?.children
			) {
				headingString += child.props.children[0];
			}
		}

		if (level && headingString) {
			const id = generateId(headingString);
			toc.push({
				level,
				id,
				title: headingString,
			});
			return headingSize(id);
		} else {
			return headingSize();
		}
	};

	function TOC({ title }: { title?: string }) {
		const sectionIds = toc.map((item) => item.id);

		const activeSectionId = useScrollSpy({
			sectionIds: sectionIds,
			offsetPx: -150,
		});

		if (toc.length === 0) {
			return null;
		}

		return (
			<div
				className="lg:border-stroke hidden lg:sticky lg:block lg:h-[80vh] lg:w-[280px] lg:shrink-0 lg:self-start lg:overflow-auto lg:border-l lg:pl-6"
				style={{
					top: tocTop,
				}}
			>
				{title ? (
					<Paragraph size="overline" color="tagline" className="mb-5">
						{title}
					</Paragraph>
				) : null}
				<ul className="toc">
					{toc.map(({ level, id, title }) => (
						<ListItem
							key={id}
							className={`toc-level-${level} !text-sm`}
							color="secondary"
						>
							<a
								href={`#${id}`}
								onClick={() =>
									spTrackWebInteraction({
										object: "toc",
										action: "anchor",
										value: `#${id}`,
									})
								}
								rel="nofollow"
								className={clsx("hover:text-blue-70 hover:underline", {
									"font-semibold": id === activeSectionId,
								})}
							>
								{title}
							</a>
						</ListItem>
					))}
				</ul>
			</div>
		);
	}

	function renderMarkdown() {
		return (
			<ReactMarkdown
				components={{
					banner: (props) => {
						return (
							<AdsBanner
								{...props}
								shouldUseRegularAnchor={!shouldUseRemixRelatedContext}
							/>
						);
					},
					youtube: Youtube,
					alert: Alert,
					mermaid: Mermaid,
					statistic: (props) => {
						return (
							<NumberItem
								{...props}
								className="rounded-lg bg-theme-secondary p-9 text-center [&>p]:text-secondary-90"
							/>
						);
					},
					code({ node, inline, className, children, style, ...props }) {
						const match = /language-(\w+)/.exec(className || "");

						const code = String(children).replace(/\n$/, "");

						// The key here will cause ts error
						// remove it from the props
						const { key, ...rest } = props;

						return !inline ? (
							<div className="border-stroke relative my-5 border">
								<SyntaxHighlighter
									customStyle={{
										backgroundColor: "rgb(var(--color-grey-0))",
										margin: "0",
									}}
									codeTagProps={{
										className: "font-code",
									}}
									children={code}
									language={match ? match[1] : "plaintext"}
									PreTag="div"
									{...rest}
								/>
								<div className="absolute right-3 top-3">
									<CopyCodeButton text={code} />
								</div>
							</div>
						) : (
							<code
								className={clsx(
									className,
									"border-stroke my-1 inline-block border bg-grey-0 px-2 font-code"
								)}
								{...props}
							>
								{children}
							</code>
						);
					},
					h1: addToTOC,
					h2: addToTOC,
					h3: addToTOC,
					h4: ({ id, children }) => (
						<H4 id={id} className="group relative mb-6 mt-3">
							{id && <HeadingAnchorLink id={id} />}
							{children}
						</H4>
					),
					h5: ({ id, children }) => (
						<H5 id={id} className="group relative mb-6 mt-3">
							{id && <HeadingAnchorLink id={id} />}
							{children}
						</H5>
					),
					h6: ({ id, children }) => (
						<H6 id={id} className="group relative mb-6 mt-3">
							{id && <HeadingAnchorLink id={id} />}
							{children}
						</H6>
					),
					p: ({ children }) => {
						return (
							<Paragraph
								whiteSpacePreLine={false}
								className="mb-5 first-of-type:mt-0"
							>
								{children}
							</Paragraph>
						);
					},
					ul: ({ children }) => (
						<BulletList className="mb-5">{children}</BulletList>
					),
					ol: ({ children }) => (
						<OrderList className="mb-5">{children}</OrderList>
					),
					li: ({ children, id }) => <ListItem id={id}>{children}</ListItem>,
					a: ({ children, href, id }) => {
						return (
							<Link
								id={id}
								className="text-link"
								to={asText(href)}
								trackingPosition="text"
								forceUseAnchorLink={!shouldUseRemixRelatedContext}
							>
								{children}
							</Link>
						);
					},
					img({ src, alt, title }) {
						const imgSrc = parseImgSrc(src);
						let validImgSrc = imgSrc;
						// remove default width from Sanity drag&drop
						if (imgSrc.endsWith("?w=450")) {
							validImgSrc = imgSrc.replace("?w=450", "");
						}

						const imgProps = isSanityImgUrl(validImgSrc)
							? getImageProps(
									getImageBuilder(validImgSrc, {
										alt,
									}),
									{
										widths: [400, 600, 800, 1000],
										sizes: ["(min-width:1024px) 600px", "100vw"],
										title,
									}
								)
							: { src: validImgSrc, alt, title };

						return <Image className="!inline-block" src={src} {...imgProps} />;
					},
					blockquote: ({ children }) => (
						<Blockquote className="relative my-8 !whitespace-normal rounded-lg bg-theme-secondary p-9 text-center [&>p]:my-0 [&>p]:text-lg [&>p]:text-secondary-90 [&>p>em]:not-italic">
							<Image
								className="inline pb-6"
								width="44px"
								height="33px"
								src={Quote}
								alt=""
							/>
							{children}
						</Blockquote>
					),
					table: ({ children }) => {
						const [head, body] = React.Children.toArray(children);

						return (
							<div className="overflow-x-auto">
								<Table className="my-layout4 min-w-[640px] table-auto text-left">
									<Table.Head className="align-top">
										{head ? (head as React.ReactElement).props.children : null}
									</Table.Head>
									<Table.Body>
										{body ? (body as React.ReactElement).props.children : null}
									</Table.Body>
								</Table>
							</div>
						);
					},
					tr: ({ children }) => <Table.Row>{children}</Table.Row>,
					td: ({ children }) => <Table.Cell>{children}</Table.Cell>,
					th: ({ children }) => <Table.Cell>{children}</Table.Cell>,
					hr: () => <Divider size="layout3" />,
				}}
				children={fixInlineMathSyntax(content)}
				rehypePlugins={[rehypeSlug, rehypeKatex]}
				remarkPlugins={[
					[remarkMath, remarkMathOptions],
					remarkBreaks,
					remarkGfm,
					remarkDirective,
					remarkDirectiveRehype,
					remarkRemoveComments,
				]}
			/>
		);
	}

	return (
		<div
			className={clsx({
				"lg:flex lg:gap-6": withToc,
			})}
		>
			<div
				className={clsx("markdown-prose", {
					"lg:flex-grow": withToc,
				})}
			>
				<div
					data-testid="markdown"
					className={clsx({
						"max-w-content": !fullWidth,
					})}
				>
					{renderMarkdown()}
				</div>
			</div>
			{withToc ? <TOC title={tocTitle} /> : null}
		</div>
	);
}
