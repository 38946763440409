import React from "react";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { PostHog } from "posthog-node";
import type { PosthogBootstrapDataType } from "./experiment";

type PHProviderProps = {
	children: React.ReactNode;
	bootstrapData?: PosthogBootstrapDataType;
};

export function PHProvider({ children, bootstrapData }: PHProviderProps) {
	if (typeof window !== "undefined" && process.env.NODE_ENV !== "test") {
		posthog.init(ENV.POSTHOG_PUBLIC_KEY, {
			api_host: "https://aiven.io/ph",
			ui_host: "https://eu.posthog.com",
			bootstrap: bootstrapData,
			autocapture: false,
			capture_pageview: false,
			capture_pageleave: false,
			disable_session_recording: true,
			opt_out_capturing_by_default: true,
		});

		// disable event capturing
		posthog.opt_out_capturing();
	}

	return <PostHogProvider client={posthog}>{children}</PostHogProvider>;
}

export function getPostHogClient() {
	const apiKey = ENV.POSTHOG_PUBLIC_KEY;
	if (ENV.POSTHOG_PUBLIC_KEY) {
		const posthogClient = new PostHog(apiKey, {
			host: "https://aiven.io/ph",
		});
		return posthogClient;
	}
}
