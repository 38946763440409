import { BlockContent } from "~/components/block-content";
import Carousel from "./carousel/carousel";
import type { SimplePortableText } from "~/types/sanity-schema";
import type { ImageProps } from "~/components/ui/image";
import { Image } from "~/components/ui/image";

export type ImageItem = ImageProps & {
	caption: SimplePortableText;
};

type ImageItemProps = {
	image?: ImageItem;
};

export function SingleImage({ image }: ImageItemProps) {
	return (
		<figure>
			{image && (
				<>
					<Image className="my-0 w-full lg:mx-auto lg:w-auto" {...image} />
					{image.caption && (
						<figcaption className="mx-auto mt-5 max-w-content text-center">
							<BlockContent value={image.caption} />
						</figcaption>
					)}
				</>
			)}
		</figure>
	);
}

type ImageSectionProps = {
	content?: ImageItem | Array<ImageItem>;
};

export function ImageSectionComponent({ content }: ImageSectionProps) {
	return Array.isArray(content) ? (
		<Carousel>
			{content.map((item) => (
				<div key={item.src} className="px-4">
					<SingleImage image={item} />
				</div>
			))}
		</Carousel>
	) : (
		<SingleImage image={content} />
	);
}
