import type {
	SanityImageSource,
	ImageUrlBuilderOptions,
} from "@sanity/image-url/lib/types/types";
import { getSanityConfig } from "./sanityConfig";
import imgBuilder from "@sanity/image-url";
import type { ImageUrlBuilder } from "@sanity/image-url/lib/types/builder";
import { removeForwardSlash } from "./misc";
import type { ImageProps } from "~/components/ui/image";
import type { MainImage } from "~/types/sanity-schema";

export function parseImgSrc(src?: string): string {
	if (!src) return "";

	if (src.startsWith("http")) return src;

	// Sometimes img src comes with the two forward slashes. We remove it and enforce https
	//(https://stackoverflow.com/questions/9646407/two-forward-slashes-in-a-url-src-href-attribute#:~:text=The%20%22two%20forward%20slashes%22%20are,to%20load%20the%20current%20page%22.)

	const srcWithoutForwardSlashes = removeForwardSlash(src);
	const withHttp = !/^https?:\/\//i.test(srcWithoutForwardSlashes)
		? `https://${srcWithoutForwardSlashes}`
		: srcWithoutForwardSlashes;

	return withHttp;
}

export function isSanityImgUrl(src: string) {
	const imgUrl = new URL(src);
	const isSanityUrl = imgUrl.host === "cdn.sanity.io";

	return isSanityUrl;
}

type ImageBuilderOptions = {
	alt?: string | null;
};

export function getImageBuilder(
	source?: SanityImageSource | null,
	options?: ImageBuilderOptions
): ImageUrlBuilder | null {
	if (
		!source ||
		(source &&
			typeof source === "object" &&
			"alt" in source &&
			source?.asset === undefined)
	) {
		return null;
	}

	const defaultQuality = 80;

	// Pass alt text to the builder options so we can get it in the getImageProps function
	function getAltText() {
		const { alt = "" } = options ?? {};

		return { alt };
	}

	const builder = imgBuilder({ ...getSanityConfig(), ...getAltText() })
		.auto("format")
		.quality(defaultQuality)
		.fit("max")
		.dpr(1.5)
		.image(source);

	return builder;
}

export type ImgPropsOptions = {
	widths?: Array<number>;
	sizes?: Array<string>;
	title?: string;
	defaultImgSrc?: string;
};

type ImageAltOptions = {
	fallback: string;
};

export function getImageAltProp(
	source: MainImage | null | undefined,
	options?: ImageAltOptions
) {
	if (!source) return "";

	const { fallback = "" } = options ?? {};

	return (
		source.alt ||
		source.asset?.altText ||
		fallback ||
		source.asset?.originalFilename
	);
}

export function getImageProps(
	imageBuilder?: ImageUrlBuilder | null,
	{ widths, sizes, title = "", defaultImgSrc }: ImgPropsOptions = {}
): ImageProps {
	let imgProps: ImageProps = { src: defaultImgSrc, alt: "" };

	if (!imageBuilder) {
		return imgProps;
	}

	const builderOptions = imageBuilder.options as ImageUrlBuilderOptions & {
		alt: string;
	};

	try {
		imgProps = {
			src: imageBuilder.url(),
			alt: builderOptions.alt,
			title,
			srcSet: widths
				?.map((width) =>
					[imageBuilder.width(width).url(), `${width}w`].join(" ")
				)
				.join(", "),
			sizes: sizes?.join(", "),
		};
	} catch (e) {
		// Silent
	}

	return imgProps;
}
