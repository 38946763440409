import { forwardRef, useRef } from "react";

export type ImageProps = React.ImgHTMLAttributes<HTMLImageElement> & {
	fetchpriority?: "high" | "low" | "auto";
};

export const Image = forwardRef<HTMLImageElement, ImageProps>(
	(props: ImageProps, ref) => {
		const localRef = useRef<HTMLImageElement>(null);
		const imageRef = ref || localRef;
		const { loading = "lazy", fetchpriority, ...rest } = props;

		return (
			<img
				aria-hidden={props["aria-hidden"] || true}
				ref={imageRef}
				loading={loading}
				// @ts-expect-error @ts-ignore react-dom doesn't have the fetchPriority type, if we use fetchpriority, React will complain
				fetchPriority={fetchpriority}
				{...rest}
			/>
		);
	}
);

Image.displayName = "Image";
