import clsx from "clsx";
import { H2, Title, Paragraph } from "~/components/ui/typography";
import { BlockContent } from "~/components/block-content";
import type { SimplePortableText } from "~/types/sanity-schema";

export type NumberProps = {
	number?: string;
	children?: SimplePortableText;
	className?: string;
};

export function NumberItem({ number, children, className }: NumberProps) {
	return (
		<article className={clsx("mx-auto max-w-content", className)}>
			{number ? (
				<Title
					size="statistic"
					fontWeight="font-semibold"
					color="theme-secondary"
				>
					{number}
				</Title>
			) : null}
			{children ? (
				children[0] && children[0]._type === "block" ? (
					<BlockContent value={children} />
				) : (
					<Paragraph size="body-large" color="secondary">
						{children}
					</Paragraph>
				)
			) : null}
		</article>
	);
}

interface NumberListProps {
	align?: "center" | "left";
	title?: string;
	subtitle?: SimplePortableText;
	numberItems: NumberProps[];
}

export function NumberList({
	align = "left",
	title,
	subtitle,
	numberItems,
}: NumberListProps) {
	return (
		<>
			{title || subtitle ? (
				<div
					className={clsx("mb-layout3 max-w-content", {
						"mx-auto text-center": align === "center",
					})}
				>
					{title ? <H2>{title}</H2> : null}
					{subtitle ? (
						<div className="mt-5">
							<BlockContent value={subtitle} />
						</div>
					) : null}
				</div>
			) : null}
			{numberItems.length > 0 ? (
				<div className="flex flex-wrap gap-layout2 sm:gap-layout4">
					{numberItems.map((item, index) => (
						<NumberItem
							key={index}
							number={item.number}
							children={item.children}
							className={clsx("flex-1", {
								"text-center": align === "center",
							})}
						/>
					))}
				</div>
			) : null}
		</>
	);
}
